import { defaults } from '../../index.js';
import { gsapConfig } from '../../index.js';
import { gsap } from "gsap/dist/gsap";
import { DrawSVGPlugin } from "../../DrawSVGPlugin";


window.addEventListener("DOMContentLoaded", () => {
 gsap.registerPlugin(DrawSVGPlugin);
 gsapConfig();
 animateVan();
 animateMug();
});

function animateVan() {
 spinWheels();
 bounceVan();
 moveRoadLine();

 function spinWheels() {
  const wheels = document.querySelectorAll(".wheel");
  gsap.to(wheels, { rotate: -360, ease: "linear", repeat: -1, });
  return;
 }
 function bounceVan() {
  const van = document.querySelectorAll(".van");
  const tl = gsap.timeline({ repeat: -1 });
  tl.to(van, { y: -3 });
  tl.to(van, { y: 3 });
  tl.to(van, { y: 0 });
  return;
 }
 function moveRoadLine() {
  const line = document.querySelectorAll(".road-front");
  gsap.to(line, { translateX: '600px', duration: defaults.duration * 3, ease: "linear", repeat: -1 });
  return;
 }

 return;
}

function animateMug() {
 const steamPaths = document.querySelectorAll(".steam-path");
 let tl = gsap.timeline({ repeat: -1 });
 tl.fromTo(steamPaths, { drawSVG: "100% 100%", opacity: 0}, { drawSVG: "0% 100%", duration: defaults.duration * 1.5, opacity: 0.2 });
 tl.to(steamPaths, {opacity: 0});
}


